import { useDispatch, useSelector } from "react-redux";

import Subtotal from "./Subtotal";
import { IsLoggedIn, UserInfo } from "../../slice/UserSlice";
import { useEffect, useRef, useState } from "react";
import UserDetail from "./userDetail/UserDetail";
import { clearCart, setCartLength } from "../../slice/CartSlice";
import { DeleteRequest, GetRequest, PostRequest } from "../../api/Request";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
  showWarningToast,
} from "../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import MobileOtp from "./userDetail/verifyOtpForPhone";
import AddressList from "../userSetting/userDetail/AddressList";
import { allProduct } from "../../slice/CartSlice";
import { AppDispatch, RootState } from "../../store/store";
import {
  Autocomplete,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import Swal from "sweetalert2";

declare var Cashfree: any; // or a more specific type if you know it

const Checkout = () => {
  const { products, subtotal, discount, totalTax, shippingCharges, total } =
    useSelector((state: RootState) => state.cart);
  // new code for billing shipping
  const [paymentMethod, setPaymentMethod] = useState<string>("CC Avenue"); // Default selected payment method

  const postalCode = [
    122001, 122002, 122003, 122004, 122005, 122006, 122007, 122008, 122009,
    122010, 122011, 122015, 122016, 122017, 122018, 122051, 122052, 122101,
    122102, 122103, 122104, 122105, 122107, 122108, 122413, 122414, 122502,
    122503, 122504, 122505, 122506, 122508, 122106, 123401,
  ];
  const apiKey = "AIzaSyCSOFMZSubnlZuRyJHvqU7PyKBRHKrisaA";

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [latitudeForBilling, setLatitudeForBilling] = useState(0);
  const [longitudeForBilling, setLongitudeForBilling] = useState(0);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [searchResultForBilling, setSearchResultForBilling] =
    useState<any>(null);
  const [hitAPI, setHitAPI] = useState<Boolean>(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    // libraries: process.env.REACT_APP_LIBRARIES as any,
    libraries: ["places"],
  });
  const containerStyle = {
    height: "30vh",
    marginBottom: "1.3rem",
    borderRadius: "0.4rem",
  };
  const [isBillingAddress, setIsBillingAddress] = useState(false);
  // new code for billing shipping address
  let userIsLoggedIn = useSelector(IsLoggedIn);
  const userDetail: any = useSelector(UserInfo);
  const [userInfo, setUserInfo] = useState<any>({
    username: "",
    mobile: { number: "", isVerified: false },
    email: "",
    otp: "",
    addresses: [],
    error: {
      email: "",
      username: "",
      number: "",
      otp: "",
    },
  });
  const [isLoadingWhilePayment, setIsLoadingWhilePayment] =
    useState<Boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoadingForOtp, setIsLoadingForOtp] = useState<Boolean>(false);
  const [otpMsg, setOtpMsg] = useState<string>("");
  const navigate = useNavigate();
  const [timer, setTimer] = useState(30);
  const [billingData, setBillingData] = useState({
    name: "",
    mobileNumber: "",
    address1: "",
    address2: "",
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "India",
    landmark: "",
  });
  const [shippingData, setShippingData] = useState({
    name: "",
    mobileNumber: "",
    address1: "",
    address2: "",
    street: "",
    city: "Gurugram",
    state: "Haryana",
    postalCode: "",
    country: "India",
    landmark: "",
  });
  const [billingAddressIsSameAsShipping, setBillingAddressIsSameAsShipping] =
    useState<boolean>(true);
  const allProducts = useSelector(allProduct);
  const [errors, setErrors] = useState<any>({});
  const [errorsForBilling, setErrorsForBilling] = useState<any>({});
  const [availablePostalCode, setAvailablePostalCode] = useState<any>();
  //phone otp
  const dispatch=useDispatch<AppDispatch>()
  const [isLoadingForVerfiyingOtp, setIsLoadingForVerfiyingOtp] =
    useState<boolean>(false);
  const [showOtpForPhone, setShowOtpForPhone] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showTimer, setShowTimer] = useState(true);
  const [phoneOTP, setPhoneOTP] = useState<string>("");

  const validateEmail = (email: string) => {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    // Check if allProducts is empty and cart in localStorage is either not present or an empty array
    const cart = localStorage.getItem("cart");
    const isCartEmpty = !cart || JSON.parse(cart).length === 0;

    if (allProducts.length === 0 && isCartEmpty) {
      navigate("/");
    }
  }, [allProducts]);

  const startTimer = () => {
    setShowTimer(true);
    setTimer(30);
  };

  const handleClosePhoneModal = () => {
    setShowOtpForPhone(() => false);
    setPhoneOTP("");
    setTimer(() => 30);
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setShowTimer(false);
    }
  }, [timer]);

  const phoneOTPHandleOnKeyDown = (event: any) => {
    setPhoneOTP(() => event.target.value);
  };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  // const getAllPostalCode = async () => {
  //   let response: any = await GetRequest("/getAll-postalCode");
  //   if (response.status === 200) {
  //     setAvailablePostalCode(() => response.data);
  //   } else {
  //     showErrorToast("failed to fetch postalCodes");
  //   }
  // };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${seconds < 10 ? "0" + seconds : seconds}`;
  };

  //these codes will check user has allowed location or not
  useEffect(() => {
    // Check if geolocation is supported

    if ("geolocation" in navigator) {
      // Query for permission status

      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          handlePermission(result);

          // Listen for changes in permission status
          result.onchange = () => {
            handlePermission(result);
          };
        })
        .catch((error) => {
          console.error("Error checking permissions:", error);
        });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handlePermission = (result: any) => {
    if (result.state === "granted") {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    } else if (result.state === "prompt") {
      requestLocationAccess();
    } else if (result.state === "denied") {
      showWarningToast(
        "Location access is denied. Please enable it in your browser settings."
      );
    }
  };

  const requestLocationAccess = () => {
    navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
  };

  const successFunction = (position: any) => {
    const lat = position?.coords?.latitude;
    const lng = position?.coords?.longitude;
    setLatitude(() => lat);
    setLongitude(() => lng);
    setLatitudeForBilling(() => lat);
    setLongitudeForBilling(() => lng);
  };

  const errorFunction = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        showWarningToast(
          "To provide you with accurate product delivery timings, please allow location access."
        );
        break;
    }
  };

  const fetchUserLocation = async (latitude: any, longitude: any) => {
    return await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    )
      .then(async (response) => response.json())
      .then(async (response: any) => {
        let state: any;
        let city: any;
        let country: any;
        let postal_code: any;
        const addressComponents = response.results[0].address_components;

        // Loop through address components
        for (const component of addressComponents) {
          const types = component.types;

          if (types.includes("locality")) {
            city = component.long_name;
          }
          if (types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (types.includes("country")) {
            country = component.long_name;
          }
          if (types.includes("postal_code")) {
            postal_code = component.long_name;
          }
        }

        if (billingAddressIsSameAsShipping) {
          const isInGurugram = await getStateDetailsUsingPinCode(
            Number(postal_code)
          );
          setShippingData((prevState: any) => {
            return {
              ...prevState,
              state,
              city,
              country,
              postalCode: postal_code,
            };
          });
          setBillingData((prevState: any) => {
            return {
              ...prevState,
              state,
              city,
              country,
              postalCode: postal_code,
            };
          });
          if (isInGurugram) {
            setErrors((prevState: any) => {
              return {
                ...prevState,
                postalCode: "",
              };
            });
          } else {
            setErrors((prevState: any) => {
              return {
                ...prevState,
                postalCode: "Currently, we only deliver in Gurugram",
              };
            });
            showInfoToast("Currently, we only deliver in Gurugram");
          }
        } else {
          setBillingData((prevState: any) => {
            return {
              ...prevState,
              state,
              city,
              country,
              postalCode: postal_code,
            };
          });
          setErrorsForBilling((prevState: any) => {
            return {
              ...prevState,
              postalCode: "",
            };
          });
        }
      })
      .catch((err) => {
        // setHitAPI(() => false);
      });
  };

  useEffect(() => {
    if (latitude !== 0 && longitude !== 0) {
      fetchUserLocation(latitude, longitude);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (
      latitudeForBilling !== 0 &&
      longitudeForBilling !== 0 &&
      !billingAddressIsSameAsShipping
    ) {
      fetchUserLocation(latitudeForBilling, longitudeForBilling);
    }
  }, [latitudeForBilling, longitudeForBilling]);

  //these codes will check user has allowed location or not

  const getStateDetailsUsingPinCode = async (pinCode: any) => {
    let newError = { ...errors };

    // List of valid Gurugram postal codes
    const postalCodes = [
      122001, 122002, 122003, 122004, 122005, 122006, 122007, 122008, 122009,
      122010, 122011, 122015, 122016, 122017, 122018, 122051, 122052, 122101,
      122102, 122103, 122104, 122105, 122107, 122108, 122413, 122414, 122502,
      122503, 122504, 122505, 122506, 122508, 122106, 123401,
    ];

    if (postalCodes.includes(pinCode)) {
      return true;
    } else {
      return false;
    }
  };

  // phone otp ends here

  const handleClickOnPayNowWhenUserIsLoggedIn = async () => {
    if (!isLoadingWhilePayment) {
      let shippingAddress;
      let billingAddress;

      if (userDetail?.addresses?.length === 0) {
        return showErrorToast("Please add shipping/billing address");
      }

      if (!userDetail.email && !userInfo.email) {
        return showErrorToast("Please add an Email");
      }

      if (userDetail?.addresses?.length > 0) {
        shippingAddress =
          userDetail.addresses.length > 0
            ? userDetail.addresses.filter((address: any) => {
                if (address.shipping) {
                  return address;
                }
              })
            : [];

        billingAddress =
          userDetail.addresses.length > 0
            ? userDetail.addresses.filter((address: any) => {
                if (address.billing) {
                  return address;
                }
              })
            : [];

        if (shippingAddress.length == 0) {
          return showErrorToast("Please select shipping address");
        }
        if (billingAddress.length == 0) {
          return showErrorToast("Please select billing address");
        }
      }
      try {
        setIsLoadingWhilePayment(true);
      
        let allProductDetail = allProducts.map((product: any) => ({
          _id: product._id,
          quantity: product.quantityByUser,
        }));
        // console.log(allProductDetail);
      // console.log(paymentMethod)
        let response = await PostRequest("createOrder", {
          payment_mode: paymentMethod,
          discount: 0,
          shippingCharge: 0,
          totalAmount: Math.round(total),
          productDetails: allProductDetail,
          shippingAddress: shippingAddress[0],
          billingAddress:
            JSON.stringify(billingAddress) === JSON.stringify(shippingAddress)
              ? ""
              : billingAddress[0],
          email: userDetail.email || userInfo.email,
        });
      // console.log(paymentMethod)
        if (response.status === 200) {
          if (paymentMethod === "CC Avenue") {
            window.location.href = await response?.data?.paymentResponse?.payment_links?.web;
          } else if (paymentMethod === "Cashfree") {
            const paymentSessionId = response.data.payment_session_id;
      
            const script = document.createElement("script");
            script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
            script.onload = () => {
              const cashfree = Cashfree({ mode: "production" });
      
              let checkoutOptions = {
                paymentSessionId: paymentSessionId,
                redirectTarget: "_blank",
              };
      
              cashfree.checkout(checkoutOptions);
            };
            document.head.appendChild(script);
          } else if (paymentMethod === "COD") {
            Swal.fire({
              icon: "success",
              title: "Order Placed",
              text: "Your order has been placed successfully with Cash on Delivery.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
      
          // Redirect after order creation is successful
          await dispatch(clearCart());
          localStorage.removeItem("cart");
          navigate("/order-info");
        } else {
          Swal.fire({
            icon: "error",
            title: "Order Placement Failed",
            text: "Unable to place order. Please try again.",
          });
        }
      } catch (error) {
        showErrorToast("Order Failed");
      } finally {
        setIsLoadingWhilePayment(false);
      }
      
      // Define the clearCart function
      
    }
  };
 
  const handleClickOnPayNowWhenUserIsNotLoggedIn = async () => {
    // Validate fields and populate errors
    if (!isLoadingWhilePayment) {
      const newErrors: any = { ...errors };
      const billingErrors: any = { ...errorsForBilling };
      const newErrorsForUserInfo = { ...userInfo.error };
      if (!shippingData.name) {
        newErrors.name = "Name is required";
      }

      if (
        !shippingData.mobileNumber ||
        shippingData.mobileNumber.length !== 10
      ) {
        newErrors.mobileNumber =
          "Mobile number is required and should be valid";
      }

      if (!shippingData.address1) {
        newErrors.address1 = "Address Line 1 is required";
      }

      if (!shippingData.street) {
        newErrors.street = "Street is required";
      }

      if (!shippingData.city) {
        newErrors.city = "City is required";
      }

      if (!shippingData.state) {
        newErrors.state = "State is required";
      }

      if (!shippingData.postalCode) {
        newErrors.postalCode = "Postal code is required";
      }
      setErrors(() => newErrors);

      if (!billingAddressIsSameAsShipping) {
        if (!billingData.name) {
          billingErrors.name = "Name is required";
        }

        if (
          !billingData.mobileNumber ||
          billingData.mobileNumber.length !== 10
        ) {
          billingErrors.mobileNumber =
            "Mobile number is required and should be valid";
        }

        if (!billingData.address1) {
          billingErrors.address1 = "Address Line 1 is required";
        }

        if (!billingData.street) {
          billingErrors.street = "Street is required";
        }

        if (!billingData.city) {
          billingErrors.city = "City is required";
        }

        if (!billingData.state) {
          billingErrors.state = "State is required";
        }

        if (!billingData.postalCode && billingData.postalCode.length < 6) {
          billingErrors.postalCode = "Postal code must be valid";
        }
        setErrorsForBilling(() => billingErrors);
      }

      const phoneNumberPattern = /^[6789]\d{9}$/;

      if (
        userInfo.mobile.number.length !== 10 ||
        !phoneNumberPattern.test(userInfo.mobile.number) ||
        !userInfo.mobile.isVerified
      ) {
        newErrorsForUserInfo.number = "Please enter a valid phone number";
      }

      if (
        userInfo.mobile.number.length == 10 &&
        phoneNumberPattern.test(userInfo.mobile.number) &&
        !userInfo.mobile.isVerified
      ) {
        newErrorsForUserInfo.number = "Please verify your phone number";
      }

      if (!userInfo.username && userInfo.username.length < 3) {
        newErrorsForUserInfo.username = "Name must be at least 3 characters.";
      }

      if (!validateEmail(userInfo.email)) {
        newErrorsForUserInfo.email = "Enter a valid email";
      }

      
      let newUserInfo = { ...userInfo };
      newUserInfo.error = newErrorsForUserInfo;

      setUserInfo(() => {
        return newUserInfo;
      });

      // Check for errors in all error objects
      const hasError =
        Object.values(newErrors).some((error) => error) ||
        Object.values(billingErrors).some((error) => error) ||
        Object.values(newErrorsForUserInfo).some((error) => error);


        if (!hasError) {
        let allProductDetail = allProducts.flatMap((product: any) => {
          return { _id: product._id, quantity: product.quantityByUser };
        });


        let payload = {
          payment_mode: paymentMethod,
          discount: 0,
          shippingCharge: 0,
          totalAmount: Math.round(total),
          productDetails: allProductDetail,
          shippingAddress: { ...shippingData, latitude, longitude },
          billingAddress: billingAddressIsSameAsShipping
            ? ""
            : {
                ...billingData,
                latitude: latitudeForBilling,
                longitude: longitudeForBilling,
              },
          email: userInfo.email,
          username: userInfo.username,
          number: userInfo.mobile.number,
        };
        // console.log(payload)

        try {
          setIsLoadingWhilePayment(true);
        
          let allProductDetail = allProducts.map((product: any) => ({
            _id: product._id,
            quantity: product.quantityByUser,
          }));
          // console.log(allProductDetail);
          // console.log(paymentMethod);
        
          // Send the payload to create the order
          let response = await PostRequest("createOrder", payload);
      
        
          if (response?.status === 200) {
            // Save the user token from the response
            localStorage.setItem("userToken", response.data.accessToken);
            // Handle payment method-specific logic
            if (paymentMethod === "CC Avenue") {
              window.location.href = response?.data?.paymentResponse?.payment_links?.web;
            } else if (paymentMethod === "Cashfree") {
              const paymentSessionId = response.data.payment_session_id;
        
              const script = document.createElement("script");
              script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
              script.onload = () => {
                const cashfree = Cashfree({ mode: "production" });
        
                const checkoutOptions = {
                  paymentSessionId: paymentSessionId,
                  redirectTarget: "_blank",
                };
        
                cashfree.checkout(checkoutOptions);
                window.location.href = "/order-info";
              };
              document.head.appendChild(script);
            } else if (paymentMethod === "COD") {
              Swal.fire({
                icon: "success",
                title: "Order Placed",
                text: "Your order has been placed successfully with Cash on Delivery.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
        
            // Redirect to the order-info page after a successful order
            await dispatch(clearCart());
            localStorage.removeItem("cart");
            dispatch(setCartLength(0))
            window.location.href = "/order-info";
          } else {
            Swal.fire({
              icon: "error",
              title: "Order Placement Failed",
              text: "Unable to place order. Please try again.",
            });
          }
        } catch (error) {
          console.error("Order creation error:", error);
          showErrorToast("Order Failed");
        } finally {
          setIsLoadingWhilePayment(false);
        }
        
      } else {
        showErrorToast("Please check all the fields");
      }
    }
  };

  const handleChange = async (e: any) => {
    const { name, value } = e.target;
    setShippingData((prevData: any) => ({
      ...prevData,
      [name]:
        name === "postalCode"
          ? value.length <= 6
            ? value
            : prevData[name]
          : value,
    }));

    setErrors((prevData: any) => ({
      ...prevData,
      [name]:
        name === "postalCode" &&
        prevData[name] !== "We don't deliver products in your area for now."
          ? prevData[name]
          : "",
    }));

    // if(is)
    if (name === "postalCode") {
      const isInGurugram = await getStateDetailsUsingPinCode(Number(value));

      if (!isInGurugram) {
        setErrors((prevData: any) => ({
          ...prevData,
          postalCode: "We only deliver in Gurugram for now.",
        }));
      } else {
        setErrors((prevData: any) => ({
          ...prevData,
          postalCode: "",
        }));
      }
    }
  };

  const handleChangeForBilling = async (e: any) => {
    const { name, value } = e.target;
    setBillingData((prevData: any) => ({
      ...prevData,
      [name]:
        name === "postalCode"
          ? value.length <= 6
            ? value
            : prevData[name]
          : value,
    }));

    setErrorsForBilling((prevData: any) => ({
      ...prevData,
      [name]: "",
    }));
  };

  // Handle sending OTP
  const handleSendOtp = async (e: React.MouseEvent) => {
    e.preventDefault();
    let { number } = userInfo.mobile;
    const phoneNumberPattern = /^[6789]\d{9}$/;
    let errorMessage = "";

    if (number.trim() === "") {
      errorMessage = "Phone number is required.";
    } else if (number.length !== 10) {
      errorMessage = "Phone number must be of 10 digits only.";
    } else if (!phoneNumberPattern.test(number)) {
      errorMessage = "Please enter a valid phone number.";
    } else {
      errorMessage = "";
    }

    setUserInfo((prevState: any) => ({
      ...prevState,
      error: {
        ...prevState.error,
        number: errorMessage,
      },
    }));

    if (errorMessage === "") {
      setIsLoadingForOtp(() => true);
      await PostRequest("send-reg-otp", { number })
        .then(async (response) => {
          if (response.status === 200) {
            showSuccessToast("Otp sent successfully");
            setShowOtpForPhone(() => true);
            await startTimer();
            // setOtpSent(() => true);
          }
        })
        .catch((error: any) => {
          showErrorToast(error?.response?.data?.message);
          if (
            error.response.data.message ==
            "User already registered and verified"
          ) {
            navigate("/login-by-otp");
          }
          setOtpMsg(() => "");
        });
      setIsLoadingForOtp(() => false);
    }
  };

  function onPlaceChanged() {
    if (searchResult != null) {
      let locationObj = searchResult.getPlace();
      setLatitude(locationObj.geometry.location.lat());
      setLongitude(locationObj.geometry.location.lng());
    }
  }

  function onPlaceChangedForBillingAddress() {
    if (searchResultForBilling != null) {
      let locationObj = searchResultForBilling.getPlace();
      setLatitudeForBilling(locationObj?.geometry?.location?.lat());
      setLongitudeForBilling(locationObj?.geometry?.location?.lng());
    }
  }

  const handleCheckboxChange = async (event: any) => {
    // const { checked } = event.target; // Get the checked state directly
    // // Clear errors if the checkbox is checked
    // if (checked) {
    //   setErrors({});
    // } else {
    //   // await fetchUserLocation();
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     city: "Gurugram",
    //     state: "Haryana",
    //     country: "India",
    //     postalCode: "",
    //   }));
    // }
    // Update the state based on the checked value
    // setIsBillingAddress(checked); // Use checked directly instead of value
  };

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  function onLoadForBilling(autocomplete: any) {
    setSearchResultForBilling(autocomplete);
  }

  const verifyPhone = async () => {
    setIsLoadingForVerfiyingOtp(() => true);
    try {
      let newShippingData = { ...shippingData };
      let newBillingData = { ...billingData };
      let newErrorForBilling = { ...errorsForBilling };
      let newErrorForShipping = { ...errors };

      // Make the API request to verify the email OTP
      const response = await PostRequest("verify-otp", {
        number: userInfo.mobile.number,
        otp: phoneOTP,
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // Update the profile state to mark the email as verified
        setUserInfo((prevState: any) => ({
          ...prevState,
          mobile: { ...prevState.mobile, isVerified: true },
        }));

        newErrorForShipping.mobileNumber = "";
        newErrorForBilling.mobileNumber = "";
        newShippingData.mobileNumber = userInfo.mobile.number;
        newBillingData.mobileNumber = userInfo.mobile.number;

        setErrors(() => newErrorForShipping);
        setErrorsForBilling(() => newErrorForBilling);
        setShippingData(() => newShippingData);
        setBillingData(() => newBillingData);

        showSuccessToast("Number got verified");
        handleClosePhoneModal();
      }
    } catch (error: any) {
      // Handle any unexpected errors
      showErrorToast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something Went Wrong"
      );
    }
    setIsLoadingForVerfiyingOtp(() => false);
  };

  const center = { lat: latitude, lng: longitude };
  const centerForBilling = {
    lat: latitudeForBilling,
    lng: longitudeForBilling,
  };

  const handleChangeForBillingOrShipping = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target; // Get the checked property of the checkbox

    setBillingAddressIsSameAsShipping(checked); // Set state directly based on checked value
    setErrorsForBilling(() => {
      return {
        name: "",
        mobileNumber: "",
        address1: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
      };
    });
  };

  return (
    <section className="bg-white py-8 mt-[5rem]">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-2 2xl:px-0">
        <div className="mt-6 sm:mt-0 flex flex-wrap justify-evenly lg:gap-12 xl:gap-16">
          <div className="min-w-0  basis-[50%] sm:basis-[98%]">
            {showOtpForPhone && (
              <MobileOtp
                setPhoneOTP={setPhoneOTP}
                setShowOtpForPhone={setShowOtpForPhone}
                showOtpForPhone={showOtpForPhone}
                phoneOTP={phoneOTP}
                timer={timer}
                phoneOTPHandleOnKeyDown={phoneOTPHandleOnKeyDown}
                startTimer={startTimer}
                showTimer={showTimer}
                handleClosePhoneModal={handleClosePhoneModal}
                formatTime={formatTime}
                activeOTPIndex={activeOTPIndex}
                inputRef={inputRef}
                setTimer={setTimer}
                sendOtpOnPhone={handleSendOtp}
                verifyPhone={verifyPhone}
                isLoadingForVerfiyingOtp={isLoadingForVerfiyingOtp}
              />
            )}

            {!userIsLoggedIn || !userDetail.email ? (
              <UserDetail
                setShippingData={setShippingData}
                shippingData={shippingData}
                billingData={billingData}
                setBillingData={setBillingData}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                isButtonDisabled={isButtonDisabled}
                isLoadingForOtp={isLoadingForOtp}
                handleSendOtp={handleSendOtp}
                errorsForShipping={errors}
                setErrorsForShipping={setErrors}
                setErrorsForBilling={setErrorsForBilling}
                errorsForBilling={errorsForBilling}
              />
            ) : (
              ""
            )}
            {userDetail?.addresses?.length > 0 || userIsLoggedIn ? (
              <>
                <AddressList checkoutPage={true} heading={"Shipping Address"} />
                {userDetail.addresses.length > 0 && (
                  <AddressList
                    checkoutPage={true}
                    heading={"Billing Address"}
                  />
                )}
              </>
            ) : (
              ""
            )}

            {!userIsLoggedIn ? (
              <>
                <div className="bg-orange-100 border border-orange-300 rounded-lg px-3 py-2 mb-6 shadow-md">
                  <div className="flex items-center mb-2">
                    <svg
                      className="w-6 h-6 text-orange-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <h4 className="text-[13px] font-bold text-orange-600 ml-2">
                      Important Note
                    </h4>
                  </div>
                  <p className="text-gray-600 text-normal sm:text-xs">
                    We only deliver within Gurugram. Please ensure your delivery
                    address is within our service area.
                  </p>{" "}
                </div>
                <div className="flex justify-between items-start mt-[0.5rem]">
                  <h1 className="font-bold text-[17px]">
                    Add Shipping Address
                  </h1>
                </div>
                <div>
                  <div className="my-[0.3rem]">
                    <input
                      id="same_billing_address"
                      type="checkbox"
                      onChange={handleChangeForBillingOrShipping}
                      checked={billingAddressIsSameAsShipping}
                      className="mr-[0.3rem] inline-block"
                    />
                    <label htmlFor="same_billing_address">
                      Mark it as billing address as well
                    </label>
                  </div>
                  <div className="relative">
                    {isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={18}
                        onClick={(event: any) => {
                          let lat = event.latLng.lat();
                          let lng = event.latLng.lng();
                          setLatitude(() => lat);
                          setLongitude(() => lng);
                        }}
                        options={{
                          mapTypeControl: false, // Hides the map type control (satellite option)
                          streetViewControl: false, // Hides the street view control
                          fullscreenControl: false, // Optionally hide the fullscreen control
                          zoomControl: true, // Keep zoom control if needed
                          styles: [
                            // Optional: Custom styles for further customization
                            {
                              featureType: "all",
                              elementType: "labels",
                              stylers: [{ visibility: "off" }], // Hide all labels
                            },
                          ],
                        }}
                      >
                        <MarkerF
                          onDragEnd={(event: any) => {
                            let lat = event.latLng.lat();
                            let lng = event.latLng.lng();
                            setLatitude(() => lat);
                            setLongitude(() => lng);
                          }}
                          draggable={true}
                          position={center}
                        />

                        <Autocomplete
                          onPlaceChanged={onPlaceChanged}
                          onLoad={onLoad}
                        >
                          <div className="flex justify-center">
                            <input
                              type="text"
                              placeholder="search your location"
                              className="md:w-[90%] w-[50%]"
                              style={{
                                border: "1px solid #E0E0E0",
                                marginTop: "1rem",
                                boxSizing: `border-box`,
                                height: `42px`,
                                padding: `0 12px`,
                                borderRadius: `5px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                              }}
                              name="searchText"
                            />
                          </div>
                        </Autocomplete>
                        <button className="w-[22px] -ml-7 inline">
                          <svg
                            className="z-50"
                            viewBox="0 0 24 24"
                            preserveAspectRatio="xMidYMid meet"
                            focusable="false"
                          >
                            <g>
                              <path d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path>
                            </g>
                          </svg>
                        </button>
                      </GoogleMap>
                    ) : (
                      <h3>{isLoaded}</h3>
                    )}
                  </div>
                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Your name*</span>
                      <input
                        name="name"
                        type="text"
                        id="name"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder="Your full name"
                        value={shippingData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-sm">{errors.name}</p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Mobile Number*</span>
                      <input
                        name="mobileNumber"
                        type="tel"
                        id="mobileNumber"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder="898989XXXX"
                        value={shippingData.mobileNumber}
                        onChange={handleChange}
                      />
                      {errors.mobileNumber && (
                        <p className="text-red-500 text-sm">
                          {errors.mobileNumber}
                        </p>
                      )}
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Line 1*</span>
                      <input
                        name="address1"
                        type="text"
                        id="address1"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={shippingData.address1}
                        onChange={handleChange}
                      />
                      {errors.address1 && (
                        <p className="text-red-500 text-sm">
                          {errors.address1}
                        </p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Line 2</span>
                      <input
                        name="address2"
                        type="text"
                        id="address2"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={shippingData.address2}
                        onChange={handleChange}
                      />
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Street*</span>
                      <input
                        name="street"
                        type="text"
                        id="street"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={shippingData.street}
                        onChange={handleChange}
                      />
                      {errors.street && (
                        <p className="text-red-500 text-sm">{errors.street}</p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">City*</span>
                      <input
                        name="city"
                        type="text"
                        id="city"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={shippingData.city}
                        onChange={handleChange}
                      />
                      {errors.city && (
                        <p className="text-red-500 text-sm">{errors.city}</p>
                      )}
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">State*</span>
                      <input
                        name="state"
                        type="text"
                        id="state"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={shippingData.state}
                        onChange={handleChange}
                      />
                      {errors.state && (
                        <p className="text-red-500 text-sm">{errors.state}</p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Postal Code*</span>
                      <input
                        name="postalCode"
                        type="tel"
                        id="postalCode"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder="eg: 111000"
                        value={shippingData.postalCode}
                        onChange={handleChange}
                        minLength={6}
                        min={6}
                        autoComplete={"false"}
                      />
                      {errors.postalCode && (
                        <p className="text-red-500 text-sm">
                          {errors.postalCode}
                        </p>
                      )}
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    {" "}
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Country*</span>
                      <input
                        name="country"
                        type="text"
                        id="country"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        value={shippingData.country}
                        readOnly={true}
                      />
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Landmark</span>
                      <input
                        name="landmark"
                        type="text"
                        id="landmark"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={shippingData.landmark}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {!userIsLoggedIn && !billingAddressIsSameAsShipping ? (
              <>
                <div className="flex justify-between items-start mt-[0.5rem]">
                  <h3 className="font-bold text-[17px]">Add Billing Address</h3>
                </div>
                <div className="relative">
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={centerForBilling}
                      zoom={18}
                      onClick={(event: any) => {
                        let lat = event.latLng.lat();
                        let lng = event.latLng.lng();
                        setLatitudeForBilling(() => lat);
                        setLongitudeForBilling(() => lng);
                      }}
                      options={{
                        mapTypeControl: false, // Hides the map type control (satellite option)
                        streetViewControl: false, // Hides the street view control
                        fullscreenControl: false, // Optionally hide the fullscreen control
                        zoomControl: true, // Keep zoom control if needed
                        styles: [
                          // Optional: Custom styles for further customization
                          {
                            featureType: "all",
                            elementType: "labels",
                            stylers: [{ visibility: "off" }], // Hide all labels
                          },
                        ],
                      }}
                    >
                      <MarkerF
                        onDragEnd={(event: any) => {
                          let lat = event.latLng.lat();
                          let lng = event.latLng.lng();
                          setLatitudeForBilling(() => lat);
                          setLongitudeForBilling(() => lng);
                        }}
                        draggable={true}
                        position={centerForBilling}
                      />

                      <Autocomplete
                        onPlaceChanged={onPlaceChangedForBillingAddress}
                        onLoad={onLoadForBilling}
                      >
                        <div className="flex justify-center">
                          <input
                            type="text"
                            placeholder="search your location"
                            className="md:w-[90%] w-[50%]"
                            style={{
                              border: "1px solid #E0E0E0",
                              marginTop: "1rem",
                              boxSizing: `border-box`,
                              height: `42px`,
                              padding: `0 12px`,
                              borderRadius: `5px`,
                              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                              fontSize: `14px`,
                              outline: `none`,
                              textOverflow: `ellipses`,
                              position: "absolute",
                            }}
                            name="searchText"
                          />
                        </div>
                      </Autocomplete>
                      <button className="w-[22px] -ml-7 inline">
                        <svg
                          className="z-50"
                          viewBox="0 0 24 24"
                          preserveAspectRatio="xMidYMid meet"
                          focusable="false"
                        >
                          <g>
                            <path d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path>
                          </g>
                        </svg>
                      </button>
                    </GoogleMap>
                  ) : (
                    <h3>{isLoaded}</h3>
                  )}
                </div>
                <div>
                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Your name*</span>
                      <input
                        name="name"
                        type="text"
                        id="name"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder="Your full name"
                        value={billingData.name}
                        onChange={handleChangeForBilling}
                      />
                      {errorsForBilling.name && (
                        <p className="text-red-500 text-sm">
                          {errorsForBilling.name}
                        </p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Mobile Number*</span>
                      <input
                        name="mobileNumber"
                        type="tel"
                        id="mobileNumber"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder="898989XXXX"
                        value={billingData.mobileNumber}
                        onChange={handleChangeForBilling}
                      />
                      {errorsForBilling.mobileNumber && (
                        <p className="text-red-500 text-sm">
                          {errorsForBilling.mobileNumber}
                        </p>
                      )}
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Line 1*</span>
                      <input
                        name="address1"
                        type="text"
                        id="address1"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={billingData.address1}
                        onChange={handleChangeForBilling}
                      />
                      {errorsForBilling.address1 && (
                        <p className="text-red-500 text-sm">
                          {errorsForBilling.address1}
                        </p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Line 2</span>
                      <input
                        name="address2"
                        type="text"
                        id="address2"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={billingData.address2}
                        onChange={handleChangeForBilling}
                      />
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Street*</span>
                      <input
                        name="street"
                        type="text"
                        id="street"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={billingData.street}
                        onChange={handleChangeForBilling}
                      />
                      {errorsForBilling.street && (
                        <p className="text-red-500 text-sm">
                          {errorsForBilling.street}
                        </p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">City*</span>
                      <input
                        name="city"
                        type="text"
                        id="city"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={billingData.city}
                        onChange={handleChangeForBilling}
                      />
                      {errorsForBilling.city && (
                        <p className="text-red-500 text-sm">
                          {errorsForBilling.city}
                        </p>
                      )}
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">State*</span>
                      <input
                        name="state"
                        type="text"
                        id="state"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={billingData.state}
                        onChange={handleChangeForBilling}
                      />
                      {errorsForBilling.state && (
                        <p className="text-red-500 text-sm">
                          {errorsForBilling.state}
                        </p>
                      )}
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Postal Code*</span>
                      <input
                        name="postalCode"
                        type="tel"
                        id="postalCode"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder="eg: 111000"
                        value={billingData.postalCode}
                        onChange={handleChangeForBilling}
                        minLength={6}
                        min={6}
                        autoComplete={"false"}
                      />
                      {errorsForBilling.postalCode && (
                        <p className="text-red-500 text-sm">
                          {errorsForBilling.postalCode}
                        </p>
                      )}
                    </label>
                  </div>

                  <div className="flex flex-wrap justify-between items-center">
                    {" "}
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Country*</span>
                      <input
                        name="country"
                        type="text"
                        id="country"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        value={billingData.country}
                        readOnly={true}
                      />
                    </label>
                    <label className="block mb-6 basis-[45%]">
                      <span className="text-gray-700">Landmark</span>
                      <input
                        name="landmark"
                        type="text"
                        id="landmark"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                        placeholder=""
                        value={billingData.landmark}
                        onChange={handleChangeForBilling}
                      />
                    </label>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* payment method */}
            <div className="space-y-4 sm:!mt-[0rem]">
              <h3 className="text-xl font-semibold text-gray-900 ">Payment</h3>

              <div className="flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    autoComplete="off"
                    id="ccavenue"
                    aria-describedby="credit-card-text"
                    type="radio"
                    name="payment-method"
                    value="CC Avenue"
                    className="h-4 w-4 border-gray-300 bg-white text-primary-600 focus:ring-2 focus:ring-primary-600 dark:ring-offset-gray-800 dark:focus:ring-primary-600"
                    checked={paymentMethod === "CC Avenue"} // Check if this method is selected
                    onChange={() => setPaymentMethod("CC Avenue")} // Update state on change
                  />
                </div>

                <div className="ms-4 text-sm">
                  <label
                    htmlFor="ccavenue"
                    className=" leading-none text-gray-900 "
                  >
                    CC Avenue Powered by HDFC
                  </label>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    autoComplete="off"
                    id="cashfree"
                    aria-describedby="credit-card-text"
                    type="radio"
                    name="payment-method"
                    value="Cashfree"
                    className="h-4 w-4 border-gray-300 bg-white text-primary-600 focus:ring-2 focus:ring-primary-600 dark:ring-offset-gray-800 dark:focus:ring-primary-600"
                    checked={paymentMethod === "Cashfree"} // Check if this method is selected
                    onChange={() => setPaymentMethod("Cashfree")} // Update state on change
                  />
                </div>

                <div className="ms-4 text-sm">
                  <label
                    htmlFor="cashfree"
                    className="leading-none text-gray-900"
                  >
                    Cashfree
                  </label>
                </div>
              </div>
              {subtotal < 10000 && (
  <div className="flex items-start">
    <div className="flex h-5 items-center">
      <input
        autoComplete="off"
        id="COD"
        aria-describedby="credit-card-text"
        type="radio"
        name="payment-method"
        value="COD"
        className="h-4 w-4 border-gray-300 bg-white text-primary-600 focus:ring-2 focus:ring-primary-600 dark:ring-offset-gray-800 dark:focus:ring-primary-600"
        checked={paymentMethod === "COD"} // Check if this method is selected
        onChange={() => setPaymentMethod("COD")} // Update state on change
      />
       <div className="ms-4 text-sm">
                  <label
                    htmlFor="COD"
                    className="leading-none text-gray-900"
                  >
                    Cash on Delivery
                  </label>
                </div>
    </div>
  </div>
)}


           
            </div>
          </div>
          <Subtotal
            handleClickOnPayNowWhenUserIsLoggedIn={
              handleClickOnPayNowWhenUserIsLoggedIn
            }
            handleClickOnPayNowWhenUserIsNotLoggedIn={
              handleClickOnPayNowWhenUserIsNotLoggedIn
            }
            isLoadingWhilePayment={isLoadingWhilePayment}
          />
        </div>
      </div>
    </section>
  );
};

export default Checkout;