import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { RootState } from "../store/store";
import { product } from "./ProductSlice";
import { DeleteRequest, GetRequest } from "../api/Request";

interface Product {
  id: number;
  name: string;
  price: number;
  quantity: number;
  image: string;
  store: string;
  subtotal: number;
}

interface CartState {
  items: [];
  products: any;
  subtotal: number;
  discount: number;
  shippingCharges: number;
  total: number;
  totalTax: number;
  flatTenPercentOff: number;
}

const initialState: any = {
  products: [],
  subtotal: 0,
  discount: 0,
  totalTax: 0,
  flatTenPercentOff: 0,
  shippingCharges: 150,
  total: 0,
  length: JSON.parse(localStorage.getItem("cartLength") || "0"), // Set length from localStorage cart or 0
};

const calculateTotal = (state: any) => {
  // Ensure subtotal is a number and has a default value
  const subtotal = state.subtotal || 0;
  const totalTax = state.totalTax || 0;

  // Check if subtotal exceeds 1 lakh
  if (subtotal > 100000) {
    const discountAmount = subtotal * 0.1; // Calculate 10% discount
    state.flatTenPercentOff = discountAmount; // Store discount amount
    state.subtotal -= discountAmount; // Apply discount to subtotal
  } else {
    state.flatTenPercentOff = 0; // Reset discount if not applicable
  }

  // Calculate total including tax and ensure it is a valid number
  state.total = Math.round(subtotal + totalTax - state.flatTenPercentOff);
};

// Helper function to update subtotal based on products in the cart
const updateSubtotal = (state: any) => {
  let subtotal = 0;
  let totalTax = 0;

  // Iterate through each product to calculate subtotal and total tax
  state.products.forEach((product: any) => {
    const basePrice = Number(product.total) || 0; // Base price of the product
    const gstRate = product.gst || 0; // GST rate (default to 0 if undefined)

    // Calculate GST for the product
    const gstAmount = (basePrice * gstRate) / (100 + gstRate) || 0; // Default to 0 if NaN

    // Update subtotal and total tax
    subtotal += basePrice - gstAmount;
    totalTax += gstAmount;
  });

  // Update state with calculated values ensuring they are numbers
  state.subtotal = subtotal || 0;
  state.totalTax = totalTax || 0;
};

// Thunk for clearing the cart
export const clearCart = createAsyncThunk(
  "cart/clearCart",
  async (_, { rejectWithValue,dispatch }) => {
    try {
      const response = await DeleteRequest("/clearCart");
      if (response.status === 200) {
        dispatch(setCartLength(0));
        return true; // Return true on success
        
      } else {
        return rejectWithValue("Failed to clear cart"); // Handle non-200 responses
      }

    } catch (error: any) {

      return rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
      
    }

  }
);

export const getAllProductInCart = createAsyncThunk(
  "cart/getAllProductInCart",
  async (_, { rejectWithValue,dispatch }) => {
    const token = localStorage.getItem("userToken"); // Check if user is logged in

    if (token) {
      // User is logged in, fetch data from the database
      try {
        const response = await GetRequest("/getCart");
        if (response.status === 200) {
          dispatch(setCartLength(response.data.items.length))

          return {
            products: response.data.items.map((item: any) => ({
              ...item.productId,
              quantityByUser: item.quantity,
              total: item.productId.salePrice * item.quantity,
            })),
            length: response.data.items.length,
          };
        }
      } catch (error: any) {
        return rejectWithValue(error.response.data.message); // Handle error
      }
    } else {
      // User is not logged in, fetch data from localStorage
      const localStorageCart = JSON.parse(localStorage.getItem("cart") || "[]");

      // Process local storage items
      const products = localStorageCart.map((item: any) => ({
        ...item,
        total: item.salePrice * item.quantityByUser, // Assuming salePrice is stored with the product
      }));

      return {
        products,
        length: products.length,
      };
    }
  }
);

// Create the cart slice with reducers
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartLength: (state, action: PayloadAction<any>) => {
      state.length = action.payload;

      // Persist the updated length to localStorage
      localStorage.setItem('cartLength', JSON.stringify(action.payload));
    },

    addProduct: (state, action: PayloadAction<any>) => {
      const existingProduct = state.products.find(
        (product: any) => product._id === action.payload._id
      );

      if (existingProduct) {
        existingProduct.quantityByUser += action.payload.quantityByUser;
        existingProduct.total =
          existingProduct.quantityByUser * Number(existingProduct.salePrice);
        showSuccessToast("Product updated in cart");
      } else {
        state.products.push({
          ...action.payload,
          total: action.payload.quantityByUser * action.payload.salePrice,
        });
        showSuccessToast("Product added to cart");
      }

      updateSubtotal(state);
      calculateTotal(state);
      
      state.length = state.products.length;

      localStorage.setItem("cart", JSON.stringify(state.products));
      localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
    },

    updateQuantity: (
      state,
      action: PayloadAction<{ id: string; amount: string }>
    ) => {
      const product = state.products.find(
        (product: any) => product._id === action.payload.id
      );

      if (product) {
        // Update the quantity based on the action amount
        if (action.payload.amount === "+1") {
          product.quantityByUser++;
        } else if (
          action.payload.amount === "-1" &&
          product.quantityByUser > 1
        ) {
          product.quantityByUser--;
        }

        // Update the total price for the product
        product.total = product.quantityByUser * product.salePrice;

        // Update subtotal and calculate totals
        updateSubtotal(state);
        calculateTotal(state); // Ensure totals are recalculated
      }
    },

    removeProduct: (state, action: PayloadAction<string>) => {
      const index = state.products.findIndex(
        (product: any) => product._id === action.payload
      );

      if (index !== -1) {
        state.products.splice(index, 1); // Remove the product

        // Update subtotal and calculate totals
        updateSubtotal(state);
        calculateTotal(state); // Ensure totals are recalculated

        // Update local storage
        localStorage.setItem("cart", JSON.stringify(state.products));
        localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
      }
    },

    clearCart: async (state) => {
      try {
        let response = await DeleteRequest("/clearCart");

        // Check if the response status is OK
        if (response && response.status === 200) {
          state.cart = {
            ...state.cart,
            products: [],
            subtotal: 0,
            discount: 0,
            totalTax: 0,
            flatTenPercentOff: 0,
            shippingCharges: 150,
            total: 0,
            length: 0,
          };
        } else {
          console.error(
            `Failed to clear cart: ${response?.statusText || "Unknown error"}`
          );
        }
      } catch (error) {
        console.error("Error clearing cart:", error); // More detailed error logging
        // showErrorToast("Something went wrong!");
      }
    },

    setCart: (state) => {
      const storedCart = localStorage.getItem("cart");
      const storedSubtotal = localStorage.getItem("subtotal");

      if (storedCart) {
        try {
          const cartFromStorage = JSON.parse(storedCart);
          state.products = cartFromStorage || [];
          updateSubtotal(state);
          calculateTotal(state); // Ensure totals are recalculated after loading from storage
        } catch (error) {
          console.error("Failed to parse cart from localStorage", error);
          state.products = [];
        }
      }

      if (storedSubtotal) {
        try {
          state.subtotal = JSON.parse(storedSubtotal) || 0;
          calculateTotal(state); // Ensure totals are recalculated after loading from storage
        } catch (error) {
          console.error("Failed to parse subtotal from localStorage", error);
          state.subtotal = 0;
        }
      }
    },
    clearFromLocalStorage: (state) => {
      state.products = [];
      state.subtotal = 0;
      state.flatTenPercentOff = 0; // Reset flat discount when clearing cart

      // Calculate totals after clearing cart
      calculateTotal(state); // Ensure totals are recalculated

      localStorage.removeItem("cart");
    },
    setProductInCart: (state, action) => {
      if (action.payload.product != false) {
        state.products[action.payload.index] = action.payload.product;
      } else {
        state.products = state.products.filter(
          (product: any, indexOfProduct: number) =>
            indexOfProduct !== action.payload.index
        );
      }
      // Update subtotal and calculate totals
      updateSubtotal(state);
      calculateTotal(state); // Ensure totals are recalculated
    },
    // Additional reducers can be added here as needed...
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductInCart.pending, (state) => {
        state.loading = true; // Set loading state
        state.error = null; // Reset error state
      })
      .addCase(getAllProductInCart.fulfilled, (state, action: any) => {
        state.loading = false; // Reset loading state
        state.products = action.payload.products; // Set fetched products
        state.length = action.payload.length;
        // Call updateSubtotal and calculateTotal after setting products
        updateSubtotal(state);
        calculateTotal(state);
      })
      .addCase(getAllProductInCart.rejected, (state, action: any) => {
        state.loading = false; // Reset loading state
        state.error = action.payload; //console.log(action.payload, state, "$$ this ");
        state.length = 0;
      })
      .addCase(clearCart.pending, (state) => {
        // Optionally set loading state if needed
      })
      .addCase(clearCart.fulfilled, (state) => {
        // Clear cart state on successful API call
        state.products = [];
        state.subtotal = 0;
        state.discount = 0;
        state.totalTax = 0;
        state.flatTenPercentOff = 0;
        state.shippingCharges = 150; // Assuming this is a fixed value
        state.total = 0;
        state.length = 0;

        // Clear local storage
        localStorage.removeItem("cart");
        localStorage.removeItem("subtotal");
        
      })
      .addCase(clearCart.rejected, (state, action) => {
        console.error("Error clearing cart:", action.payload); // Log error message
        // showErrorToast("Cleared Successfully");
        // Optionally handle error state here if needed
      });
  },
});

// Export actions and reducer
export const {
  addProduct,
  updateQuantity,
  removeProduct,

  setCart,
  setCartLength,
  setProductInCart,
  clearFromLocalStorage
} = cartSlice.actions;

export default cartSlice.reducer;

export const totalCartItem = (state: RootState) => {

  return state.cart.length;
};

export const allProduct = (state: RootState) => {
  return state.cart.products;
};
