// firebase-messaging.js
import  { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { PostRequest } from '../api/Request'; // Adjust the path as needed

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain:process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING__SENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Function to request permission and get token
export const requestForToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBAE_VAPIDKEY });
            if (token) {
                await PostRequest('/saveToken', { token });
            }
        } else {
            console.warn('Notification permission not granted');
            return undefined;
        }
    } catch (error) {
        console.error('Error retrieving token', error);
        return undefined;
    }
};

// Function to listen for incoming messages
// export const listenForMessages = () => {
//     onMessage(messaging, (payload:any) => {
//         console.log('Message received: ', payload);
//         const notificationTitle = payload.notification.title || 'New Notification';
//         const notificationBody = payload.notification.body || 'You have new updates.';
//         showNotification(notificationTitle, notificationBody);
//     });
// };

// Function to show notification
// const showNotification = (title:any, body:any) => {
//     if (Notification.permission === 'granted') {
//         const options = {
//             body: body,
//             icon: '/path/to/icon.png', // Path to your notification icon
//         };

//         const notification = new Notification(title, options);
//         notification.onclick = () => {
//             window.focus();
//             notification.close();
//         };

//         setTimeout(() => {
//             notification.close();
//         }, 4000); // Notification closes after 4 seconds
//     } else {
//         console.warn('Notification permission not granted');
//     }
// };

// Usage example in a React component
const NotificationComponent = () => {
    useEffect(() => {
        requestForToken(); // Request token on mount
        // listenForMessages(); // Listen for messages
    }, []);

    return null; // Or your component JSX
};

export default NotificationComponent;
