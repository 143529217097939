import axios from "axios";

// const BASE_URL = 'http://192.168.1.111:3000/v1/';   

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export const axiosRequest = axios.create({
  baseURL:  process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
});
