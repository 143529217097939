import { Helmet } from "react-helmet";

const ReturnRefundPolicy = () => {
  return (
    <div className="return-refund-policy-container mt-[7rem] w-[95%] mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
       <Helmet>
        <title>Return and Refund Policy - Cayro Enterprises</title>
        <meta
          name="description"
          content="Read our Return and Refund Policy to understand the process of returning products and getting refunds at Cayro Enterprises."
        />
      </Helmet>
      <h1 className="text-2xl  sm:text-lg font-bold mb-4">
        Return and Refund Policy
      </h1>
      <p className="mb-4">
        Thank you for shopping at <strong>Cayro Enterprises</strong>. We are
        committed to ensuring your satisfaction with our products. Please read
        our Return and Refund Policy carefully to understand your rights and
        obligations regarding returns, exchanges, and refunds.
      </p>

      <h2 className="text-lg font-semibold mt-6">1. Eligibility for Returns</h2>
      <p className="mb-2">
        We accept returns for most electronics and home appliances within 3 days
        of the delivery date, provided that the following conditions are met:
      </p>
      <ul className="list-disc ml-5 mb-4">
        <li>
          The item must be in its original packaging, unused, and in the same
          condition as it was received.
        </li>
        <li>
          The item must include all accessories, manuals, and warranty cards.
        </li>
        <li>
          Proof of purchase, such as a receipt or order confirmation, must be
          provided.
        </li>
      </ul>
      <p className="mb-2">
        Note: Certain items may be ineligible for returns, including:
      </p>
      <ul className="list-disc ml-5 mb-4">
        <li>Items marked as "final sale" or "non-returnable."</li>
        <li>Products that have been used, installed, or modified.</li>
        <li>Perishable items or consumables (e.g., batteries, filters).</li>
        <li>Digital products, software, or downloadable content.</li>
      </ul>

      <h2 className="text-lg font-semibold mt-6">
        2. How to Initiate a Return
      </h2>
      <p className="mb-2">To initiate a return, please follow these steps:</p>
      <ol className="list-decimal ml-5 mb-4">
        <li>
          Contact Us: Reach out to our customer service team at{" "}
          <a
            href="mailto:info@cayroshop.com"
            className="text-blue-600 underline"
          >
            info@cayroshop.com
          </a>{" "}
          or 9599383349 within 3 days of receiving your item. Provide your order
          number, details of the product, and the reason for the return.
        </li>
        <li>
          Receive Return Authorization: Our customer service team will provide
          you with a Return Merchandise Authorization (RMA) number and
          instructions on how to proceed with the return.
        </li>
        <li>
          Package Your Item: Securely package the item in its original
          packaging, including all accessories and documentation. Clearly write
          the RMA number on the outside of the package.
        </li>
        <li>
          Ship the Item: Send the package to the return address provided by our
          customer service team. You are responsible for the shipping costs of
          returning the item, unless the return is due to our error (e.g.,
          incorrect or defective item).
        </li>
      </ol>

      <h2 className="text-lg font-semibold mt-6">3. Refund Process</h2>
      <p className="mb-2">
        Once we receive and inspect your returned item, we will notify you via
        email of the approval or rejection of your refund. If approved, your
        refund will be processed, and a credit will be applied to your original
        payment method within 3 business days.
      </p>
      <ul className="list-disc ml-5 mb-4">
        <li>
          Full Refunds: Will be issued for items returned in new, unused
          condition with all original packaging and accessories.
        </li>
        <li>
          Partial Refunds: May be issued for items that show signs of use, are
          missing accessories, or are not returned in their original condition.
          A restocking fee of 15% may apply.
        </li>
        <li>
          Non-Refundable Items: Items that are not eligible for returns as per
          our policy will not be refunded.
        </li>
      </ul>

      <h2 className="text-lg font-semibold mt-6">4. Exchanges</h2>
      <p className="mb-4">
        If you received a defective or damaged item, or if you wish to exchange
        an item for a different size or model, please contact our customer
        service team at{" "}
        <a href="mailto:info@cayroshop.com" className="text-blue-600 underline">
          info@cayroshop.com
        </a>{" "}
        or 9599383349. We will provide instructions on how to proceed with the
        exchange. Exchanges are subject to product availability.
      </p>

      <h2 className="text-lg font-semibold mt-6">5. Non-Returnable Items</h2>
      <p className="mb-2">The following items are non-returnable:</p>
      <ul className="list-disc ml-5 mb-4">
        <li>Gift cards.</li>
        <li>Downloadable software or digital products.</li>
        <li>Items that have been customized or personalized.</li>
        <li>Items that have been installed or altered in any way.</li>
      </ul>

      <h2 className="text-lg font-semibold mt-6">
        6. Defective or Damaged Items
      </h2>
      <p className="mb-4">
        If you receive a defective or damaged item, please notify us within 3
        days of receiving your order. We will arrange for a replacement or
        refund, depending on the availability of the product. Please provide
        photos of the damaged item and packaging to assist with the return
        process.
      </p>

      <h2 className="text-lg font-semibold mt-6">7. Warranty Claims</h2>
      <p className="mb-4">
        Most electronics and home appliances come with a manufacturer's
        warranty. If your product develops a fault within the warranty period,
        please contact the manufacturer directly to make a warranty claim. If
        you need assistance with the process, our customer service team is here
        to help.
      </p>

      <h2 className="text-lg font-semibold mt-6">8. Late or Missing Refunds</h2>
      <p className="mb-2">
        If you have not received your refund within the specified time frame,
        please follow these steps:
      </p>
      <ol className="list-decimal ml-5 mb-4">
        <li>Check your bank account or credit card statement.</li>
        <li>
          Contact your bank or credit card company; there may be a processing
          delay.
        </li>
        <li>
          If you have completed the above steps and still have not received your
          refund, please contact us at{" "}
          <a
            href="mailto:info@cayroshop.com"
            className="text-blue-600 underline"
          >
            info@cayroshop.com
          </a>
          .
        </li>
      </ol>

      <h2 className="text-lg font-semibold mt-6">9. Return Shipping Costs</h2>
      <ul className="list-disc ml-5 mb-4">
        <li>
          Customer-Initiated Returns: You are responsible for the shipping costs
          associated with returning an item, unless the return is due to our
          error (e.g., incorrect or defective item).
        </li>
        <li>
          Company-Initiated Returns: If the return is due to an error on our
          part (e.g., shipping the wrong item), we will cover the cost of return
          shipping.
        </li>
      </ul>

      <h2 className="text-lg font-semibold mt-6">
        10. Changes to the Return and Refund Policy
      </h2>
      <p className="mb-4">
        We reserve the right to update or modify this Return and Refund Policy
        at any time without prior notice. Any changes will be effective
        immediately upon posting on this website. Your continued use of the
        website following the posting of changes constitutes your acceptance of
        such changes.
      </p>

      <h2 className="text-lg font-semibold mt-6">Contact Information</h2>
      <p className="mb-4">
        If you have any questions or concerns about our Return and Refund
        Policy, please contact us at:
      </p>
      <ul className="list-disc ml-5">
        <li>
          Email:{" "}
          <a
            href="mailto:info@cayroshop.com"
            className="text-blue-600 underline"
          >
            info@cayroshop.com
          </a>
        </li>
        <li>Phone: 9599383349</li>
        <li>
          Address: Dcg, 104, Dlf Corporate Greens, Sector 74A, Gurugram,
          Haryana-122001
        </li>
      </ul>
    </div>
  );
};

export default ReturnRefundPolicy;
