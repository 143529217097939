import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DeleteRequest, GetRequest } from "../../../../api/Request";
import { useSelector } from "react-redux";
interface RatingBarProps {
  stars: number;
  percentage: number;
}


// Function to render stars based on rating
const renderStars = (rating: number) => {
  const fullStars = Math.floor(rating);
  const totalStars = 5;

  return (
    <span>
      {/* Render filled stars */}
      {[...Array(fullStars)]?.map((_, index) => (
        <span key={index} style={{ color: "#FFD700", fontSize: "20px" }}>★</span> // Filled star
      ))}
      {/* Render outlined stars for remaining */}
      {[...Array(totalStars - fullStars)]?.map((_, index) => (
        <span key={index} style={{ color: "#DDDDDD", fontSize: "20px" }}>☆</span> // Outlined star
      ))}
    </span>
  );
};



export const Reviews = (props: any) => {
  const { product } = props;
  const { user } = useSelector((state: any) => state.user);
  const [reviews, setReviews] = useState<any[]>([]);
  const [avgRating, setAvgRating] = useState(0);
  const [reviewId, setReviewId] = useState('');
  const [message,setMessage]=useState('')
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await GetRequest(`/getAllProductReview/${product._id}`);
        const fetchedReviews = Array.isArray(response.data.reviews) ? response.data.reviews : [];

        // Set reviews state
        setReviews(fetchedReviews);

        // Calculate average rating
        const totalRating = fetchedReviews.reduce((acc: number, review: any) => acc + Number(review.rating), 0);
        const average = fetchedReviews.length > 0 ? totalRating / fetchedReviews.length : 0;
        setAvgRating(average);

        // Find the review for the current user
        const userReview = fetchedReviews.find((review: any) => review?.userId?._id === user?._id);
        if (userReview) {
          setReviewId(userReview._id); // Set the reviewId of the current user's review
        }

      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    fetchReviews();
  }, [product?._id, user?._id]);

  
  return (
    <div className="p-4 relative">
      <h2 className="text-xl font-bold">Customer Ratings</h2>

      {/* Button to write a review */}
      {/* <Link
        to={`/review?productId=${product._id}&reviewId=${reviewId}`} // Use reviewId of the current user's review
        className="absolute top-0 right-0 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
      >
        Write a Review
      </Link> */}

      {/* Display average rating */}
      <div className="flex items-center mb-4">
        <span className="text-lg font-semibold">Average Rating:</span>
        <span className="ml-2 text-lg text-gray-800">{avgRating?.toFixed(1)} out of 5</span>
        <div className="ml-2">{renderStars(avgRating)}</div>
      </div>

      {/* Display individual reviews */}
      {reviews?.length > 0 ? (
        reviews.map((review: any) => (
          <div key={review?._id} className="my-4 border-b pb-4">
            <div className="flex items-center mb-2">
              {/* Render star rating for each review */}
              <div className="flex items-center">{renderStars(Number(review?.rating))}</div>
              <span className="ml-2 text-sm text-gray-600">{review?.rating} out of 5</span>
            </div>
            <div className="text-sm text-gray-500">By {review?.userId?.username}</div>
            <p className="text-gray-700 mt-1">{review?.comment}</p>

            {/* Images */}
            {review?.images?.length > 0 && (
              <div className="flex space-x-4 mt-2">
                {review.images.map((image: any, index: number) => (
                  <img key={index} src={image.url} alt={`Review ${index}`} className="h-20 w-20 object-cover" />
                ))}
              </div>
            )}

            <div className="flex mt-2">
              <Link
                to="/"
                className="flex text-xs bg-gray-200 hover:bg-gray-100 text-black py-2 px-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-all"
              >
                ★ Helpful
              </Link>
              <Link
                to="/"
                className="ml-2 flex text-xs bg-gray-200 hover:bg-gray-100 text-black py-2 px-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-all"
              >
                ☆ Report
              </Link>
              
              {/* Delete button, only visible if the review belongs to the logged-in user */}
              
            </div>
          </div>
        ))
      ) : (
        <p>No reviews available</p>
      )}
    </div>
  );

};
