import { Link, useNavigate } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdKeyboardVoice,
  MdLocalOffer,
} from "react-icons/md";
import { TbCategory2 } from "react-icons/tb";
import Recording from "../../loti/recording.json";
import { FaRegHeart, FaUser } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { BsFire } from "react-icons/bs";
import { useCallback, useEffect, useRef, useState } from "react";
import Category from "./Category";
import Sidebar from "./SidebarForMobile";
import { RxHamburgerMenu } from "react-icons/rx";
import { GetRequest, PostRequest } from "../../api/Request";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { RootState } from "../../store/store";
import { IsLoggedIn, UserInfo, logout } from "../../slice/UserSlice";
import { getAllProductInCart, setCartLength, totalCartItem } from "../../slice/CartSlice";
import { debounce } from "lodash";
import Loader from "../../common/loader/Loader";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet";

const Header = (props: any) => {
  let { allCategory, isLoadingForCategory } = props;

  const [isSpeaking, setIsSpeaking] = useState<boolean>(false); // State to track if user is speaking
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [allProductForSearchDropdown, setAllProductForSearchDropdown] =
    useState<any>([]);
  const [isLoadingForSearchDropdown, setIsLoadingForSearchDropdown] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const isLoggedIn = useSelector(IsLoggedIn);
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
  const [errorForSearchingProduct, setErrorForSearchingProduct] =
    useState<string>("");
  const [showSideBarInMobile, setShowSideBarInMobile] =
    useState<boolean>(false);
  const cartItem = useSelector(totalCartItem);
  const searchRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  const handleClickOutsideOnProfile = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Close dropdown when a link is clicked
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  // Add event listener for clicks outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideOnProfile);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOnProfile);
    };
  }, []);

  const handleClickOnCategory = () => {
    setShowCategory((prevState: any) => {
      return !prevState;
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickOnLogout = async () => {
    try {
      let response = await PostRequest("/user-Logout");
      if (response.status === 200) {
        localStorage.removeItem("userToken");
        navigate("/login-by-otp");
        dispatch(logout());
        dispatch(setCartLength(0))
        handleLinkClick();
        showSuccessToast("Logged out successfully");
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };

  const debouncedSearchProduct = useCallback(
    debounce(async (query) => {
      setIsSpeaking(false);
      setIsLoadingForSearchDropdown(true);
      if (query) {
        try {
          let response = await GetRequest(`/searchProduct/${query}`);

          setErrorForSearchingProduct("");
          setAllProductForSearchDropdown(() => {
            return response.data.length > 0
              ? response.data
              : response.data.products;
          });
        } catch (error: any) {
          if (error?.response?.status === 404) {
            setAllProductForSearchDropdown([]);
            setErrorForSearchingProduct(error.response.data.message);
          }
        }
      }
      setIsLoadingForSearchDropdown(false);
    }, 500), // Set debounce time to 2000 milliseconds
    []
  );

  useEffect(() => {
    debouncedSearchProduct(searchQuery);

    return () => {
      debouncedSearchProduct.cancel();
    };
  }, [searchQuery, debouncedSearchProduct]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(() => value);
    if (value.length > 0) {
    } else {
      setAllProductForSearchDropdown([]);
      setErrorForSearchingProduct("");
    }
    setIsSpeaking(false); // User
  };


  const handleVoiceSearch = async (): Promise<void> => {
    const SpeechRecognition =
      window.SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true; // Keep listening
      recognition.interimResults = true;

      let inactivityTimer: NodeJS.Timeout;

      // Function to reset the inactivity timer
      const resetInactivityTimer = (): void => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
          recognition.stop(); // Stop recognition after 5 seconds of inactivity
          showInfoToast("🤔 Still thinking what to search");
        }, 4000); // 5000 milliseconds = 5 seconds
      };

      recognition.onstart = (): void => {
        setIsSpeaking(true); // User has started speaking
        resetInactivityTimer(); // Start the inactivity timer
      };

      recognition.onresult = (event: any): void => {
        const transcript = event.results[event.resultIndex][0].transcript;
        setSearchQuery(transcript); // Set recognized text in the search box
        setIsSpeaking(() => false);
        clearTimeout(inactivityTimer);
        // resetInactivityTimer();
        // Reset the inactivity timer on speech input
      };

      recognition.onerror = (event: any): void => {
        setIsSpeaking(false);
        // showErrorToast("Speech recognition error: " + event.error);
      };

      recognition.onend = (): void => {
        setIsSpeaking(false); // User has stopped speaking
        clearTimeout(inactivityTimer); // Clear the timer when recognition ends
      };

      recognition.start();
    } else {
      alert("Speech recognition is not supported in this browser.");
    }
  };

  const handleClickOutside = (event: { target: any }) => {
    if (searchRef.current && !searchRef?.current?.contains(event.target)) {
      setIsSpeaking(false); // User
      setAllProductForSearchDropdown(() => {
        return [];
      });
      setIsLoadingForSearchDropdown(() => false);
      setErrorForSearchingProduct(() => "");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const generateCategoryStructuredData = () => {
    if (!allCategory || allCategory.length === 0) return null;
  
    // Generate structured data for each category and return an array of script tags
    return allCategory.map((category: any) => {
      const structuredData = {
        "@context": "https://schema.org/",
        "@type": "ProductCategory", // Use 'ProductCategory' type for categories
        name: category?.category || "Category Name",
        image: category?.image?.url || "default-category-image.jpg", // First image URL
        description: category?.metaInfo?.metaInfoDescription || "Category Description",
        metaInfo: {
          title: category?.metaInfo?.metaInfoTitle || "Category Title",
          keywords: category?.metaInfo?.metaInfoKeyword || [],
        },
       
        status: category?.status || 1, // Default to 1 if not present
        updatedAt: category?.updatedAt || null,
      };
  
      return (
        <script key={category._id} type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      );
    });
  };
  const generateCategoryMetaTags = () => {
    if (!allCategory || allCategory.length === 0) return null;
  
    // Generate individual meta tags for each category
    return allCategory.map((category: any) => {
      const metaDescription = category?.metaInfo?.metaInfoDescription || 
        ` ${category?.category || "products"} collection${category?.metaInfo?.metaInfoKeyword?.length > 0 ? 
        `,${category.metaInfo.metaInfoKeyword.join(", ")}` : ""}`;
  
      return (
        <meta
          key={`${category._id}`}
          name={`${category.category}`}
          content={metaDescription}
        />
      );
    });
  };
  
  // Use it inside Helmet
  
  const structuredDataScripts = generateCategoryStructuredData();
  return (
    <header className="bg-primary p-[1.5rem] lg:p-[0.5rem]  w-[100%] fixed top-0 z-[99999] text-[white] sm:!pb-[0.7rem]">
        <Helmet>
        {structuredDataScripts && structuredDataScripts.map((script: any) => script)}

        {generateCategoryMetaTags()}
        </Helmet>
      <div className="flex justify-between w-full flex-wrap items-center">
        <div className="flex items-center basis-[20%]">
          {screenSize <= 600 && (
            <div className="text-center">
              <button
                onClick={() => setShowSideBarInMobile(true)}
                className="text-white bg-transparent font-medium rounded-lg text-sm mt-[0.3rem] mr-[0.5rem]"
                type="button"
                data-drawer-target="drawer-body-scrolling"
                data-drawer-show="drawer-body-scrolling"
                data-drawer-body-scrolling="true"
                aria-controls="drawer-body-scrolling"
              >
                <RxHamburgerMenu className="text-[1.3rem]" />
              </button>
            </div>
          )}

          <Link to={"/"} className="flex items-center">
            <span className="text-[white] text-[1.8rem] sm:text-[1rem] mb-[0.3rem] sm:mb-[0rem] font-bold tracking-wide">
              Cayro
            </span>

            <img
              className="w-[80px]"
              src={`${process.env.PUBLIC_URL}/asset/logo/best-for-shopping.png`}
              alt="cayro logo"
            />
          </Link>
          <div
            className="flex lg:hidden items-center cursor-pointer ml-[5rem]"
            onClick={handleClickOnCategory}
          >
            <TbCategory2 className="white ml-[1rem] mr-[0.5rem]" />
            Categories
            <MdKeyboardArrowDown className="ml-[0.3rem]" />
          </div>
        </div>

        <div className="relative basis-[36%] lg:mt-[0.4rem] lg:basis-[100%] flex justify-center ">
        <div className="relative text-gray-600 focus-within:text-gray-400 w-full  sm:basis-1/2 md:basis-1/2 lg:basis-full">
        {
              <span
                className={
                  isLoadingForSearchDropdown
                    ? "absolute left-[-8px] bottom-[44px] bottom-[40px] flex items-center pl-2"
                    : "absolute left-[-3px] bottom-[9px] lg:bottom-[4px] flex items-center pl-2"
                }
              >
                {isLoadingForSearchDropdown ? (
                  <Loader />
                ) : (
                  <button
                    type="submit"
                    onClick={() => debouncedSearchProduct(searchQuery)}
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-[#a8a8a8]"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                )}
              </span>
            }
             
             <div className="relative flex items-center rounded-l-[10px] lg:rounded-r-[10px] bg-white">
             <input
    type="text"
    onChange={handleSearch}
    value={searchQuery}
    name="searchQuery"
    className="py-[0.9rem] w-full sm:py-[0.6rem] text-sm lg:rounded-md text-[black] pr-[6px] rounded-l-md pl-10 focus:outline-none"
    placeholder="Search your product"
    autoComplete="off"
  />
  <button
    className={`flex items-center justify-center text-[26px] ml-2 ${
      isSpeaking ? "text-red-500" : "text-gray-500"
    }`}
    onClick={handleVoiceSearch}
  >
    {isSpeaking ? (
      <Lottie
        animationData={Recording}
        loop={true}
        className="w-[3rem] h-[3rem] sm:w-[2rem]"
      />
    ) : (
      <MdKeyboardVoice />
    )}
  </button>
</div>


           
          </div>
          {/* search bar dropdown */}
          {errorForSearchingProduct ||
          allProductForSearchDropdown.length > 0 ? (
            <div
              className="absolute w-full right-0 z-10 mt-2 top-[44px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              ref={searchRef}
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              <div
                className="py-1 max-h-[230px] overflow-scroll no-scrollbar"
                role="none"
              >
                {errorForSearchingProduct && (
                  <p className="flex justify-center items-center 	m-[0.4rem] pb-[0.4rem]  text-sm text-red-500">
                    {errorForSearchingProduct}
                  </p>
                )}

{allProductForSearchDropdown.length > 0 &&
  allProductForSearchDropdown.map((product: any) => {
    return (
      <Link
  to={`/product?name=${product.productName.replace(/\s+/g, '-')}&product_id=${
    product._id || product.id
  }`}
        className="flex items-center border-b-[0.4px] border-[#ddd]	m-[0.4rem] pb-[0.4rem]  text-sm text-gray-700"
        role="menuitem"
        id="menu-item-0"
        onClick={() => {
          setAllProductForSearchDropdown(() => []);
          setSearchQuery(() => "");
        }}
      >
        <span className="flex items-center">
          <button
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="w-5 h-5  text-[#a8a8a8]"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </span>
        <span className="mb-[0.2rem] ml-[0.4rem] text-blue-600">
          {/* Display the second character of the first segment after splitting by comma */}
          {product.productName.split(",").slice(0, 2).join(", ")}
        </span>
      </Link>
    );
  })}

              </div>
            </div>
          ) : (
            ""
          )}
          <div className="flex items-center lg:hidden basis-[50%] z-[88998] bg-white pr-[6px] rounded-r-md  focus:outline-none justify-evenly">
            <Link
              to="https://cayroshop.com/product-list?category=Daliy-deal&category_id=66b7361d04044857c3f2b2b9&page=1"
              className="bg-[#EDF1F4] p-[0.5rem] text-[0.75rem] text-[#08091b] rounded-lg w-[100px] hover:bg-gray-200"
            >
              <MdLocalOffer className="inline text-green-400" /> Daily deals
            </Link>
            <Link
              to="https://cayroshop.com/product-list?category=Hot-deals&category_id=672dd6c82e201e6f17c5ef19&page=1"
              className="bg-[#EDF1F4] ml-[0.3rem] p-[0.5rem] text-[0.75rem] text-[#08091b] rounded-lg w-[100px] hover:bg-gray-200"
            >
              <BsFire className="inline text-[red]" /> Hot deals
            </Link>
          </div>
        </div>

        <div className="flex items-center basis-[18%] justify-center  lg:absolute lg:top-[12px] lg:right-[15px] ">
          {isLoggedIn ? (
            <Link
              to="/profile"
              className="mr-[0.7rem] sm:mr-0 sm:hidden sm:text-[0.9rem]"
            >
              Profile
            </Link>
          ) : (
            ""
          )}

          {!isLoggedIn ? (
            <div className="relative">
              <p
                onClick={toggleDropdown}
                className="text-[0.9rem] sm:!inline-block   mr-[0.9rem] cursor-pointer"
              >
                <FaUser className="text-[17.5px]" />
              </p>
              {isOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute sm:w-[130px] right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div className="py-1" role="none">
                    {/* <Link
                      to="/register"
                      onClick={handleLinkClick}
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-0"
                    > */}
                      {/* <svg
                      className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                      />
                    </svg> */}
                      {/* Register
                    </Link> */}
                    <Link
                      to="/login-by-otp"
                      onClick={handleLinkClick}
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-1"
                    >
                      {/* <svg
                      className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900 dark:group-hover:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                      <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                      <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                    </svg> */}
                      Login/Register
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="relative">
              <p
                onClick={toggleDropdown}
                className="text-[0.9rem] sm:!inline-block cursor-pointer  xxl:hidden mr-[0.9rem]"
              >
                <FaUser />
              </p>
              {isOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute sm:w-[130px] right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div className="py-1" role="none">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-2"
                      onClick={handleLinkClick}
                    >
                      {/* <FaUser /> */}
                      Profile
                    </Link>
                    <Link
                      to="/order-info"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      onClick={handleLinkClick}
                      id="menu-item-2"
                    >
                      {/* <FaFirstOrder className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900" />{" "} */}
                      Your Orders
                    </Link>
                    <Link
                      to="/list-of-address"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-2"
                      onClick={handleLinkClick}
                    >
                      {/* <FaLocationArrow className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900" /> */}
                      Address
                    </Link>
                    <button
                      type="submit"
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-3"
                      onClick={handleClickOnLogout}
                    >
                      {/* <svg
                      className="flex-shrink-0 w-2 inline-block h-2 text-primary transition duration-75 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                      />
                    </svg>{" "} */}
                      Log out
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          <Link to="/wishlist">
            <FaRegHeart className="text-lg text-[1.5rem] sm:text-[1.1rem]" />
          </Link>
          {/* cart items with count */}
          <Link to="/cart" className="relative sm:text-[0.9rem]">
            <IoCartOutline className="text-[1.7rem] sm:text-[1.5rem] font-bold ml-[0.9rem]" />
            <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-whitesmoke bg-red-500 rounded-full -top-1 -end-2">
              {cartItem}
            </div>
          </Link>
          {/* <div className="flex items-center lg:hidden ml-[1rem]"> */}
          {/* {isLoggedIn ? (
              <Link to="/profile">
                <FaRegUser />
              </Link>
            ) : (
              <FaRegUser />
            )} */}
          {/* <div className="flex flex-col ml-[0.3rem]"> */}
          {/* <Link
                to={userDetail.name ? "/profile" : "/"}
                className="text-[whitesmoke] text-[0.75rem]"
              >
                {userDetail.name ? userDetail.name : ""}
              </Link> */}
          {/* {!isLoggedIn
                ? ""
                 : <p className="text-[0.75rem]">
                    <Link to="/login">Login /</Link>
                    <Link to="/register"> Register</Link>
                  </p>
                  <p className="text-[0.75rem] cursor-pointer">
                    <span onClick={handleClickOnLogout}> Logout</span>
                  </p>
                  ""} */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      {showSideBarInMobile && (
        <Sidebar
          allCategory={allCategory}
          setShowSideBarInMobile={setShowSideBarInMobile}
        />
      )}
      {showCategory && (
        <div className="absolute top-[90%] left-[14%]">
          <Category
            allCategory={allCategory}
            setShowCategory={setShowCategory}
            isLoadingForCategory={isLoadingForCategory}
          />
        </div>
      )}
    </header>
  );
};

export default Header;
