import React, { useState } from "react";
import Description from "./tabs/Description";
import AdditionalInfo from "./tabs/AdditionalInfo";

import MoreOffers from "./tabs/MoreOffers";
import Policies from "./tabs/Policies";
import Inquiries from "./tabs/Inquiries";
import TechnicalInfo from "./tabs/TechnicalInfo";
import { Reviews } from "./tabs/Reviews";

interface PartTwoProps {
  product: any; // Replace 'any' with the actual type of product
}

const ProductTabs: React.FC<PartTwoProps> = (props: any) => {
  let { product } = props;
  const [activeTab, setActiveTab] = useState("description");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const tabs = [
    { id: "description", label: "Description" },
    { id: "additional_information", label: "Additional Information" },
    // { id: "technical_information", label: "Technical Information" },
    { id: "reviews", label: "Reviews" },
    // { id: "more_offers", label: "More Offers" },
    // { id: "store_policies", label: "Store Policies" },
    // { id: "inquiries", label: "Ask Questions" },
  ];

  return (
    <div className="w-full">
      {/* Tab Buttons */}
      <ul className="flex border-b no-scrollbar overflow-x-scroll">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className={`mr-1 ${
              activeTab === tab.id
                ? "border-b-2 border-gray-800"
                : "border-b border-transparent"
            }`}
          >
            <button
              className="bg-white text-md sm:p-3 sm:text-sm sm:font-semibold inline-block p-6 text-gray-900 font-bold hover:text-green-600 focus:outline-none m-auto justify-between"
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>

      {/* Tab Content */}
      <div className="mt-4">
        {/* Description Tab Content */}
        {activeTab === "description" && <Description product={product} />}

        {/* Additional Information Tab Content */}
        {activeTab === "additional_information" && (
          <AdditionalInfo product={product} />
        )}
        {activeTab === "technical_information" && (
          <TechnicalInfo product={product} />
        )}

        {/* Reviews Tab Content */}
        {activeTab === "reviews" && <Reviews product={product} />}

        {/* More Offers Tab Content */}
        {activeTab === "more_offers" && <MoreOffers product={product} />}

        {/* Store Policies Tab Content */}
        {activeTab === "store_policies" && <Policies product={product} />}

        {/* Inquiries Tab Content */}
        {activeTab === "inquiries" && <Inquiries product={product} />}
      </div>
    </div>
  );
};

export default ProductTabs;
