import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import SuccessPayment from "../../loti/successPayment.json";

interface MyComponentProps {
  orderId: string | null;
  message: string | null;
  amount: string | null;
}

const OrderSuccess: React.FC<MyComponentProps> = (props: any) => {
  const { orderId, message, amount } = props;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative bg-white relative rounded-lg shadow-lg max-w-lg w-[300px] h-[230px] p-6 transform transition-all duration-300 ease-in-out scale-100 hover:scale-105">
          <Link
            to={"/order-info?page=1"}
            className="absolute flex text-xs justify-center items-center top-2 right-2 h-[20px] w-[20px] bg-red-500 text-white rounded-full  focus:outline-none hover:bg-red-600 transition-colors duration-300 ease-in-out"
          >
            ✕
          </Link>
          <div className="text-center">
            <h1
              className={
                message === "Payment done successfully"
                  ? "text-2xl font-bold mb-2 text-green-400"
                  : "text-2xl font-bold mb-2 text-red-400"
              }
            >
              {message}
            </h1>
            <p className="text-gray-700 mb-2 ">Order Id :- #{orderId} </p>
            <p className="mb-2"> Amount :- ₹ {amount}</p>
            <Link
              to="/order-info"
              // className="bg-gradient-to-r mt-[2rem] from-yellow-500 to-red-500 text-white px-6 py-3 rounded-lg hover:from-black-600 hover:to-red-600 transition-all duration-300 ease-in-out"
              className="mt-[2rem]"
            >
              Check Your Orders
            </Link>
          </div>
          {message === "Payment done successfully" ? (
            <div className="absolute top-0 left-0 z-[-1] bottom-0 right-0">
              <Lottie
                animationData={SuccessPayment}
                className="w-[300px] h-[250px]"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
